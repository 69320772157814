.header-box {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: red;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.header-right-box {
  margin-right: 20px;
}