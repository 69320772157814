body {
  margin: 0;
  background-color: white !important;
  font-family:  "Roboto", "Oxygen",-apple-system, BlinkMacSystemFont, "Segoe UI",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
   #some { display:none; }

  .myDivToPrint {
      /* background-color: red; */
      height: 100%;
      width: 100%;
      /* position: fixed; */
      top: 0;
      left: 0;
      margin: 0;
      /* padding: 10px; */
      font-size: 14px;
      line-height: 18px;
      /* border: solid 2px green; */
      display: block;
      page-break-inside: avoid;
  }
}

/* #card {

  min-height: 500 !important;
  max-width: 200 !important;
  max-height: 500 !important;
  min-width: 200 !important;
 }
 .card {

  min-height: 500 !important;
  max-width: 200 !important;
  max-height: 500 !important;
  min-width: 200 !important;
 } */
/* .myDivToPrint {
  height:0;
  width: 0;
} */

